<template>
  <div class="production_wrap">
    <div class = "box">
      <div class="about-title">综合管理部</div>
      <div class = "block-line"></div>
      <div class="about-container">
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <b><span style="font-size:16px;">综合管理部主要职责：</span></b>
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">主要承担公司日常行政管理工作，包括党建、资金、财务、文电、会务、机要、人事、档案、印信、信访维稳、安全生产、保密、对外联络、车辆管理、固定资产管理、后勤服务、节能减排、日常物品采购及综合治理等。</span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;"><b>副部长：丁誉恒（负责人）、赵天博</b></span> 
        </p>
        <img width="80%" style="margin: 10px 10% 0" src="../../assets/htmlimg/“综合部”大.jpeg" alt="" />
      </div>
    </div>
    <CallMe />
    <MobileCallme />
  </div>
</template>

<script>
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
export default {
  data() {
    return {
    }
  },
  components: {
    CallMe,
    MobileCallme,
  },
}
</script>

<style lang="scss" scoped>
.production_wrap {
  padding-top: 200px;
  width: 100%;
}
</style>